import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import UtilHelper from "../UtilHelper";
import RiskRating from "./RiskRating";
import PieGraph from "./PieGraph";
import ScheduledLoanTime from "./ScheduledLoanTime";
import {useTimer} from "../utils/subscriptionUtils";
import SubscriptionStatus from "../../utils/SubscriptionStatus";
import RiskRatingBar from "./RiskRatingBar";
import StatusRibbon from "./StatusRibbon";
import StatusBadge from "./StatusBadge";
import UnderwriterBanner from "./UnderwriterBanner";

const ALLOCATION_TYPE_REGULAR = 1;
const ALLOCATION_TYPE_BOOKBUILDING = 2;

function SubscriptionBlock({subscription}) {
    const [t, i18n] = useTranslation();
    const timeLeft = useTimer(subscription.subscription_ends_at);

    // This adjusts the height of all the push blocks to match the highest block length
    const matchBlocksHeight = () => {
        let height = 0;
        let blocks = document.querySelectorAll('.block-grid-item .upper');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => {
            if (block.offsetHeight > height) {
                height = block.offsetHeight;
            }
        });

        blocks.forEach(block => {
            block.style.height = height + 'px';
        });
    }

    useEffect(() => {
        matchBlocksHeight();

        let pieCharts = document.querySelectorAll('.pie-chart svg');

        // display outer enclosing pie-chart on hover and hide on mouseleave event
        pieCharts.forEach(chart => {
            chart.addEventListener('mouseenter', function (e) {
                let container = e.target.closest('div.chart-composite');
                let outerChart = container.querySelector('[data-chart-type="outer"]');

                if (outerChart) {
                    outerChart.style.display = 'block';
                }
            });

            chart.addEventListener('mouseleave', function (e) {
                let container = e.target.closest('div.chart-composite');
                let outerChart = container.querySelector('[data-chart-type="outer"]');

                if (outerChart) {
                    outerChart.style.display = 'none';
                }
            });
        });

    }, []);

    const status = subscription.subscription_state.state_id;
    const underwriterIsActive = !SubscriptionStatus.closedStates().includes(status) && subscription.underwriter_state

    let countryCode = subscription.available_kiis_languages;

    let imageUrl = 'https://placehold.co/640x400';
    if (subscription.image) {
        imageUrl = subscription.image;
    }

    const subscribedPercentage = useMemo(
        () => Math.floor((subscription.subscribed_amount / subscription.application_amount) * 100),
        [subscription.subscribed_amount, subscription.application_amount]
    );

    const chartData = useMemo(() => {
        if (
            subscription.allocation_type_id === ALLOCATION_TYPE_REGULAR
            || ![SubscriptionStatus.BOOK_BUILDING_OPEN, SubscriptionStatus.closedBookBuildingStates()].includes(subscription.status_id)
        ) {
            return [
                subscribedPercentage,
                100 - subscribedPercentage
            ];
        }

        return [
            subscribedPercentage % 100,
            (100-(subscribedPercentage % 100)),
        ];
    }, [subscribedPercentage]);

    let innerPieColors = [
        "var(--blue)",
        subscribedPercentage > 100 ? 'var(--lighter-blue-shade)' :"var(--lighter-blue-light)"
    ] // Blue and gray colors used for inner pie-chart to indicate filled, vs remaining amount
    let outerPieColors = [
        "var(--lightest-blue-light)",
        "var(--lightest-blue-light)"
    ] // light colors used for outer pie-chart

    return (
        <div className='subscription-block'>
            <div className="block-grid-item">
                <div className='content-view-grid-item class-investment-option'>
                    <div className={`infobox infobox-standalone`}>
                        <StatusRibbon status={status}/>
                        <a href={`/listing/investment-option/${subscription.loan_application_id}`}>
                            <div className='image-wrapper'>
                                <UnderwriterBanner isShown={underwriterIsActive} />
                                <StatusBadge status={status} />
                                <div style={{
                                    width: '100%',
                                    height: '190px',
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center bottom',
                                }}
                                ></div>
                                <img className="flag" src={`/extension/kameo/design/kameo_v2/images/flag_${countryCode.toLowerCase()}.png`} alt={countryCode} />
                            </div>
                            <div className="wrapper">
                                <div className="upper">
                                    <h3>{subscription.title}</h3>
                                    <p>{subscription.teaser}</p>
                                    <ScheduledLoanTime subscription={subscription} />
                                </div>
                                <div className="lower clearfix">
                                    <RiskRatingBar riskRating={subscription.risk_score}/>

                                    <div className="chart-composite">
                                        <div className="underlay">
                                            {t('amounts.complete')}
                                            <strong>{subscribedPercentage.toString()}%</strong>
                                        </div>

                                        <PieGraph width={120} height={120} xPosition={65} yPosition={65} innerRadius={40} outerRadius={50} data={chartData} position='relative' colors={innerPieColors} chartType='inner' />
                                        <PieGraph width={130} height={130} xPosition={65} yPosition={65} innerRadius={51} outerRadius={61} data={chartData} position='absolute' colors={outerPieColors} top={0} chartType='outer' />
                                    </div>
                                    <div className="loan-amount">
                                        <span>{t('amounts.loanAmount')}</span>
                                        <strong>{UtilHelper.parseMoneyFromCurrency(subscription.application_amount, subscription.currency, 0)}</strong>
                                    </div>

                                    <div className="interest-rate">
                                        <div>
                                            <span className="st">{t("descriptors.averageInterestRate")}</span>
                                            <strong>{subscription.annual_interest_rate}%</strong>
                                            <span className="nd">{t("descriptors.averageInterestRate")}</span>
                                        </div>
                                    </div>

                                    <div className="time-left">
                                        {
                                            subscription.subscription_ends_at
                                            &&
                                            <small>{t('subscription_timing.timeLeft')}</small>
                                        }
                                        {
                                            !subscription.subscription_ends_at
                                            &&
                                            <small>{t('subscription_timing.openEnded')}</small>
                                        }

                                        {
                                            status !== 'scheduled'
                                            &&
                                            <ul className="list-inline">
                                                <li>
                                                    <strong>{timeLeft.days}</strong><span>{t("subscription_timing.days")}</span>
                                                </li>
                                                <li>
                                                    <strong>{timeLeft.hours}</strong><span>{t("subscription_timing.hours")}</span>
                                                </li>
                                                <li>
                                                    <strong>{timeLeft.minutes}</strong><span>{t("subscription_timing.minutes")}</span>
                                                </li>
                                            </ul>
                                        }

                                        {
                                            status === 'scheduled'
                                            &&
                                            <ul className="list-inline">
                                                <li>
                                                    <strong style={{'color': 'gray'}}>0</strong><span>{t("subscription_timing.days")}</span>
                                                </li>
                                                <li>
                                                    <strong style={{'color': 'gray'}}>0</strong><span>{t("subscription_timing.hours")}</span>
                                                </li>
                                                <li>
                                                    <strong style={{'color': 'gray'}}>0</strong><span>{t("subscription_timing.minutes")}</span>
                                                </li>
                                            </ul>
                                        }

                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionBlock;
