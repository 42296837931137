import AjaxHelper from "./AjaxHelper";
import UtilHelper from "./UtilHelper";
import TrapetsHelper from "./TrapetsHelper";

class UserManager {
    static USER = {
        get token() {
            return document.getElementById('ezxform_token_js') ?. getAttribute('content')
        },
        get password() {
            return document.getElementById('password').value.trim();
        }
    }

    async handleUserActivation(email, userId) {
        //let email = document.getElementById("email").value;

        return await AjaxHelper.Investor.sendActivationEmail(email, userId);
    }

    static handleUserActivationUsingEmailAndCode(email, code) {
        let result = {
            success: false,
            message: 'Din email kunde inte aktiveras.',
        };

        return AjaxHelper.User.validateCodeUsingEmail(email, code).then(response => {
            if (response.data.error_text !== "") {
                result.message = response.data.error_text;
                return JSON.stringify(result);
            }

            result.message = response.data.content.message;
            result.success = response.data.content.status === 'success';

            return JSON.stringify(result);
        });
    }

    static handleSubmit(email, code) {
        let result = {
            success: false,
            message: 'Din email kunde inte aktiveras.',
        };

        // TODO: Update the selection here. We should really consider having a React based selection rather than a very specific DOM selection
        //email = document.querySelector("#email.form-input.address-input").value     // TODO: Terrible selection

        return AjaxHelper.User.validateCodeUsingEmail(email, code).then(response => {
            if (response.data.error_text !== "") {
                result.message = response.data.error_text;
                return JSON.stringify(result);
            }

            if (response.data.content) {
                result.message = 'signup.email.accountActivated';
                result.success = true;

                if (!document.getElementById("ezxform_token_js")) {
                    const metaToken = document.createElement("meta");
                    metaToken.setAttribute("id", "ezxform_token_js");
                    metaToken.setAttribute("name", "csrf-token");
                    document.head.appendChild(metaToken);
                }
                document.getElementById("ezxform_token_js")?.setAttribute('title', response?.data?.content?.ezxform_token);
                document.getElementById("ezxform_token_js")?.setAttribute('content', response?.data?.content?.ezxform_token);

                return JSON.stringify(result);
            }

            return JSON.stringify(result);
        });
    }

    static validatePersonalNo(page, signupMethod, t, setEnabled, customerType, fieldName='ssn' ) {
        let personalNo = document.getElementById("ssn").value;
        let country = document.getElementById("nationality").value;
        let orgNumber = document.getElementById("orgNumber").value;

        let loaderWrapper = page + ' .loader-container-ssn';
        if (fieldName === "orgNumber") {
            loaderWrapper = page + ' .pos-rel';
        }

        UtilHelper.showLoader(true, loaderWrapper);

        AjaxHelper.User.validatePersonalNo(personalNo, orgNumber, country, signupMethod, customerType).then(response => {
            if (response.data.error_text !== "") {
                setEnabled(false);

                UtilHelper.manageCheckMark(false, page);
                UtilHelper.showLoader(false, loaderWrapper);
                return UtilHelper.showAlert('danger', t(response.data.error_text), page);
            }
            if (response.data.content.success === false) {
                setEnabled(false);
                UtilHelper.manageCheckMark(false, page);
                UtilHelper.showLoader(false, loaderWrapper);

                if (fieldName === "orgNumber") {
                    return UtilHelper.showAlert('danger', t(response.data.content.message), page);
                }
                return UtilHelper.showAlert('danger', t(response.data.content.message), page);
            }

            // Activate Submit button
            let startPage = document.getElementById(page);

            if (startPage) {
                if (response.data.content.lock) {
                    startPage.classList.add('disable-click');
                } else {
                    setEnabled(true);
                    startPage.classList.remove('disable-click');
                }
            }

            UtilHelper.manageCheckMark(true, page);
            UtilHelper.showLoader(false, loaderWrapper);
            UtilHelper.hideAlert(page);
        });
    }

    static #createTempEmailFromPersonalNumber(personalNumber, nationality) {
        return personalNumber + '@investorregistration.' + nationality.toLowerCase();
    }

    async createInvestor(personalNumber, nationality, orgNumber, customerType, authenticationMethod = 'BankID') {
        //let email = UserManager.#createTempEmailFromPersonalNumber(personalNumber, nationality);

        return await AjaxHelper.Investor.getInvestor(personalNumber, nationality, orgNumber, customerType, authenticationMethod).then(response => {
            return response;
        });
    }

    static #getCustomerType(investor) {
        if (parseInt(investor.type) === 1) {
            return TrapetsHelper.CUSTOMER.COMPANY
        }

        return TrapetsHelper.CUSTOMER.PRIVATE;
    }

    /**
     * Called form AddressForm, returns true if email is disabled. Takes customer as argument, customer is the React customer state which contains different investor related data
     * @todo check for email activation, disable editing if email is already activated.
     * @param customer
     * @param authentication
     * @returns {boolean}
     */
    static isEmailActivated(customer, authentication) {
        // Email is already activated during light registration
        if (customer.emailActivated) {
            return true;
        }

        // @todo Change it to check for email activation
        if (!customer.tempUser && authentication === TrapetsHelper.SIGNUP.DOCUMENT_UPLOAD) {
            return true;
        }

        return false;
    }

    /**
     * returns true if investor has performed light registration before coming to full registration
     * @param dataJson
     * @returns {*}
     */
    static #isEmailActivated(dataJson) {
        let parsedJson = JSON.parse(dataJson);
        return parsedJson ?. emailActivated === true;
    }

    static prepareCustomer(response, customer, setCustomer) {
        let investor = response.investor;

        setCustomer({
            ...customer,
            customerId: investor ? parseInt(investor.id) : null,
            authenticated: response.authenticated,
            customerKey: investor ?. investorRegistrationKey,
            nationality: investor ?. nationality,
            email: investor ?. email,
            personalNo: investor.personalNo,
            userId: investor ? parseInt(investor.createdBy) : null,
            customerType: investor ? this.#getCustomerType(investor) : null,
            tempUser: investor ? parseInt(investor.isTempUser) !== 0 : 1,
            emailActivated: response.emailActivated,
            isKycComplete: investor ? parseInt(investor.isKycComplete) !== 0 : 0,
            registrationStatus: investor ? parseInt(investor.state) : '',
            isSignoffFulfilled: response.isSignoffFulfilled,
            isSignoffApproved: response.isSignoffApproved,
            isSignoffComplete: response.isSignoffCompleted,
            passwordAdded: response.passwordAdded,
            isPOAUploaded: response.isPOAUploaded,
            marketingConsent: response.marketingConsent,
            netWorth: response.netWorth,
            netWorthCurrency: response.netWorthCurrency,
            period: response.period,
            knowledgeTestTaken: response.knowledgeTestTaken,
            knowledgeTestStatus: response.knowledgeTestStatus,
            knowledgeTestAdditionalKYCUsed: response.knowledgeTestAdditionalKYCUsed,
            sophisticationLevel: response.sophisticationLevel,
            sophisticationLevelAdditionalKYCUsed: response.sophisticationLevelAdditionalKYCUsed,
            sophisticationKycComplete: response.sophisticationKycComplete,
            exchangeRate: response.exchangeRate,
            locale: response.locale
        });
    }

    static prepareInvestorUploadedDocs(investor, setDocuments) {
        setDocuments(documents => ({
            ...documents,
            id_doc: {
                id: investor ?. identityDocument ?. id,
                originalFileName: investor ?. identityDocument ?. originalFileName
            },
            mailed_doc: {
                id: investor ?. mailedDocument ?. id,
                originalFileName: investor ?. mailedDocument ?. originalFileName
            }
        }));
    }
}

export default UserManager;
