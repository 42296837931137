import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import sv_translation from './locales/sv/translation.json';
import da_translation from './locales/da/translation.json';
import no_translation from './locales/no/translation.json';
//import {translations} from './locales/translations'

const DETECTION_OPTIONS = {
    order: ['htmlTag','path','navigator','localStorage','cookie']
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        fallbackLng: 'sv',
        //lng: 'sv', // Explicitly set language, good for testing translations on localhost
        supportedLngs: ['sv', 'da', 'no'],
        ns: ['translation'],
        debug: false,
        resources: {
            sv: {
                translation: sv_translation,
            },
            da: {
                translation: da_translation,
            },
            no: {
                translation: no_translation,
            }
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
