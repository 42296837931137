import React, {useEffect, useState} from "react";
import SubscriptionStatus from "../../utils/SubscriptionStatus";
import {useTranslation} from "react-i18next";
const StatusBadge = ({ status }) => {
    const [badgeContent, setBadgeContent] = useState(null);
    const { t } = useTranslation();


    useEffect(() => {
        switch (true) {
            case SubscriptionStatus.closedBookBuildingStates().includes(status):
                setBadgeContent(t('statuses.distributionPending'));
                break;
            default:
                setBadgeContent(null);
        }
    }, [status]);

    if (!badgeContent) {
        return (<></>);
    }

    return (
        <div className={'status-badge-container'}>
            <div className={'status-badge'}>
                <span>{badgeContent}</span>
            </div>
        </div>
    )
}

export default StatusBadge;