import React, {useEffect, useState} from "react";
import KameoAPIV1Request from "../utils/KameoAPIV1Request";
import SubscriptionBlock from "./SubscriptionBlock";

/**
 * Calls backend listing endpoint and returns platform text for the push block for FrontOffice
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Listing({offset, limit, subscriptionOriginSweden, subscriptionOriginNorway, subscriptionOriginDenmark}) {
    const [list, setList] = useState(null);

    const listSubscriptions = () => {
        const urlParams = new URLSearchParams({
            subscription_origin_sweden: subscriptionOriginSweden,
            subscription_origin_norway: subscriptionOriginNorway,
            subscription_origin_denmark: subscriptionOriginDenmark,
            limit: limit,
            page: (offset + limit) / limit
        });
        KameoAPIV1Request.axiosInstance.get(`loans/listing/investment-options?${urlParams.toString()}`).then(response => {
            setList(response.data.data.investment_options)
        })
    };

    useEffect(() => {
        listSubscriptions();
    }, []);

    if (!list) {
        return <></>;
    }

    return (
        <div className={'container listing-grid' + (list.length <= 2 ? ' listing-flex' : '')}>
            {
                list.map(
                    (subscription, index) => <SubscriptionBlock key={index} subscription={subscription} />
                )
            }
        </div>
    );
}

export default Listing;
