import SubscriptionStatus from "../../utils/SubscriptionStatus";
import React, {useEffect, useState} from "react";
import {t} from "i18next";


const StatusRibbon = ({ status }) => {
    const [ribbonContent, setRibbonContent] = useState(null);
    const [classes, setClasses] = useState('ribbon');

    useEffect(() => {
        if (SubscriptionStatus.closedBookBuildingStates().includes(status)) {
            setClasses('ribbon ribbon-bookbuilding-closed');
        }

        if (status === SubscriptionStatus.SCHEDULED) {
            setClasses('ribbon ribbon-coming');
        }
    }, [status]);


    useEffect(() => {
        switch (true) {
            case SubscriptionStatus.closedBookBuildingStates().includes(status):
                setRibbonContent(null);
                break;
            case status === SubscriptionStatus.SCHEDULED:
                setRibbonContent(t('statuses.comingSoon'));
                break;
            case SubscriptionStatus.closedStates().includes(status):
                setRibbonContent(t('statuses.closed'));
                break;
            default:
                setRibbonContent(null);
        }
    }, [status]);

    if (!ribbonContent) {
        return (<></>);
    }

    return (
        <div className={classes}>
            <span>{ribbonContent}</span>
        </div>
    )
}

export default StatusRibbon;