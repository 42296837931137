import axios from 'axios'
import qs from 'qs'

let source;
const config = {
    baseURL: '/ezjscore/call/',
}

let csrfToken = '';
let xsrfToken = document.getElementById("ezxform_token_js")?.value;
const setXsrfToken = (token) => xsrfToken = token;
const getCsrfToken = () => { csrfToken = document.getElementById("ezxform_token_js")?.getAttribute('title') };

const get = (uri) => axios.get(uri, config).then((res) => (res));

const post = (uri, body) => {
    source = axios.CancelToken.source();
    getCsrfToken(); // This will set csrfToken to the latest token on each Ajax request.

    if (uri !== 'kameo_investor_onboarding::createCustomer' && body.hasOwnProperty('customerId')) {
        const idPrefix = document.getElementById('idPrefix').value
        body.customerId = idPrefix + body.customerId;
    }

    return axios.post(uri, qs.stringify({ezxform_token: csrfToken, ...body}), {cancelToken: source.token, ...config}).then((res) => (res));
};

const postFile = (uri, formData) => {
    getCsrfToken(); // This will set csrfToken to the latest token on each Ajax request.
    formData.append('ezxform_token', csrfToken);

    return axios.post(uri, formData, {headers: {'Content-Type': 'multipart/form-data'}, ...config}).then((res) => (res));
}

const User = {
    isEnabled: userId => post('kameo_investor_onboarding::isEzUserEnabled', {userId}),
    //checkCode: (userId, code) => post('kameo_investor_onboarding::checkCode', {userId, code}),
    validateCodeUsingEmail: (email, code) => post('kameo_investor_onboarding::validateCodeUsingEmail', {email, code}),
    validatePersonalNo: (personalNo, orgNo, country, signupMethod, customerType) => post('kameo::validatePersonalNo', {personalNo, orgNo, country, signupMethod, customerType}),
}

const Investor = {
    updateInvestorAuthenticationMethod: (authenticationMethod) => post('kameo_investor_onboarding::updateInvestorAuthenticationMethod', {authenticationMethod}),
    exists: (email) => post('kameo_investor_onboarding::investorExists', {email}),
    sendActivationEmail: (email, userId) => post('kameo_investor_onboarding::sendActivationEmail', {email, userId}),
    resendEmail: (email) => post('kameo_investor_onboarding::resendActivationEmail', {email}),
    create: (data) => post('kameo_investor_onboarding::createInvestor', {data}),
    getInvestor: (personalNo, nationality, orgNumber, customerType, authenticationMethod) => post('kameo_investor_onboarding::getInvestor', {personalNo, nationality, orgNumber, customerType, authenticationMethod}),
    createLightInvestor: (data) => post('kameo_investor_onboarding::createLightInvestor', {data}),
    uploadDocumentToEz: (formData) => postFile('kameo_investor_onboarding::uploadDocumentToEz', formData), // @todo move it to the Document namespace below.
    updatePassword: (password) => post('kameo_investor_onboarding::updatePassword', {password}),
    getByKey: investorRegistrationKey => post('kameo_investor_onboarding::getByKey', {}),
    fetchBankIdSession: () => post('kameo_investor_onboarding::fetchBankIdSession', {}),
    getBankIDSessionByInvestorId: () => post('kameo_investor_onboarding::getBankIDSessionByInvestorId', {}),
    loadSignOff: (key) => post('kameo_signoff2::loadSignOff', {key}),
    deleteUploadedSignoffDocument: (investorRegistrationKey, fileId, documentType) => post('kameo_signoff_management::deleteUploadedDocument', {investorRegistrationKey, fileId, documentType}),
    toggleSet: (reference, set_identifier, status) => post('kameo_signoff2::toggleSet', {
        reference,
        set_identifier,
        status
    }),
    saveAgreements: (customerId, customerType, nationality, reference, agreements, answer, documents, authentication) => post('kameo_investor_onboarding::acceptSignOff', {
        customerId,
        customerType,
        nationality,
        reference,
        agreements,
        answer,
        documents,
        authentication
    }),
    saveInvestorNetWorth: (investorRegistrationKey, data) => post('kameo_investor_onboarding::saveInvestorNetWorth', {investorRegistrationKey, data})
};

const Document = {
    deleteDocumentFromEz: (fileId, fileType) => post('kameo_investor_onboarding::deleteDocumentFromeZ', {fileId, fileType}),
}

const ExternalBankAccounts = {
    details: () => post('kameo_investor_profile_update_request::getBankAccountDetails', {}),
    makeDefault: (id) => post('kameo_investor_profile_update_request::setDefaultBankAccount', {bankDetailsId: id}),
    createAccount: (data, respondWithUpdatedData) => post('kameo_investor_profile_update_request::createBankAccount', {
        data,
        respondWithUpdatedData
    }),
    editAccount: (data, respondWithUpdatedData) => post('kameo_investor_profile_update_request::editBankAccount', {
        data,
        respondWithUpdatedData
    }),
    deleteAccount: (id, respondWithUpdatedData) => post('kameo_investor_profile_update_request::deleteBankAccount', {
        id,
        respondWithUpdatedData
    }),
    loadSignOffView: (signoffId) => post('kameo_signoff2::load', {signoff_id: signoffId}),
    cancelBankUpdate: () => post('kameo_investor_profile_update_request::cancelAllPendingBankDetailsUpdateRequests', {}),
}

const Signicat = {
    createSession: () => post('kameo_investor_onboarding::createSignicateSession')
};

const Trapets = {
    kycGoToStartPage: (customerId, customerType) => post('kameo_investor_onboarding::goToStartPage', {
        customerType
    }),
    kycGoToLastPage: (customerId, customerType, questionnaireType) => post('kameo_investor_onboarding::goToLastPage', {
        customerId,
        customerType,
        questionnaireType
    }),
    kycGoToCurrentPage: (customerId, customerType, questionnaireType) => post('kameo_investor_onboarding::goToCurrentPage', {
        customerType,
        questionnaireType
    }),
    kycGoToNextPage: (customerId, customerType, questionnaireType, answer) => post('kameo_investor_onboarding::goToNextPage', {
        customerType,
        questionnaireType,
        answer
    }),
    kycGoToPreviousPage: (customerId, customerType, questionnaireType, answer) => post('kameo_investor_onboarding::goToPreviousPage', {
        customerType,
        questionnaireType,
        answer
    }),
    uploadDocument: (customerId, customerType, questionnaireType, fileUploaderId, formData) => postFile('kameo_investor_onboarding::uploadDocument', formData),
    deleteDocument: (customerId, customerType, questionnaireType, fileId) => post('kameo_investor_onboarding::deleteDocument', {
        customerType,
        questionnaireType,
        fileId
    }),
    getQuestionnaireByAliasAndCustomerId: (customerId, customerType, alias, isSignoffComplete = false) => post('kameo_investor_onboarding::getQuestionnaireByAliasAndCustomerId', {
        customerType,
        alias,
        isSignoffComplete
    }),

    getParsedQuestionnaire: (investorId, questionnaireAlias) => post('kameo_investor_onboarding::getParsedQuestionnaire', {investorId, questionnaireAlias}),
    createCustomer: (customerId, data) => post('kameo_investor_onboarding::createCustomer', {data}),
    loadSignOff: (signoff_reference) => post('kameo_signoff2::load', {signoff_reference}),
    createUBO: (data) => post('kameo_customer_property::createUBO', {data}),
    getUBO: () => post('kameo_customer_property::getUBO', {})
};

const General = {
    getAllCountries: (locale) => post('kameo_investor_onboarding::getAllCountries', {locale})
}

const Ecspr = {
    saveInvestorNetWorth: (investorRegistrationKey, data) => post('kameo_ecspr::saveInvestorNetWorth', {investorRegistrationKey, data}),
    updateKnowledgeTest: (investorRegistrationKey, status, questionType, restart) => post('kameo_ecspr::updateKnowledgeTest', {investorRegistrationKey, status, questionType, restart}),
    updateSophisticatedInvestorStatus: (investorRegistrationKey, status, questionType, isKYCComplete) => post('kameo_ecspr::updateSophisticatedInvestorStatus', {investorRegistrationKey, status, questionType, isKYCComplete}),
};

const PlatformText = {
    listing: (offset, limit, subscriptionOriginSweden, subscriptionOriginNorway, subscriptionOriginDenmark, displayHidden = false) => post('kameo_platform_text::listing', {offset, limit, subscriptionOriginSweden, subscriptionOriginNorway, subscriptionOriginDenmark, displayHidden}),
};

const Light_Bidding = {
    load: platformTextId => get('kameo_light_bidding_widget::load::' + platformTextId)
}

export default {
    User, Document, Investor, Signicat, Trapets, setXsrfToken, ExternalBankAccounts, General, Ecspr, PlatformText, Light_Bidding
};
