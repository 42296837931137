import React from "react";

function RiskRating({rating, actualRating}) {
    if (rating === actualRating) {
        return <div className="progress-bar progress-bar-info" role="progressbar" style={{"width": "20%"}}>{rating}</div>
    }

    return <div className="progress-bar" role="progressbar" style={{"width": "20%"}}>{rating}</div>
}

export default RiskRating;
