import React from 'react';
import {useTranslation} from "react-i18next";

const UnderwriterBanner = ({ isShown }) => {
    if (!isShown) {
        return null;
    }

    const { t} = useTranslation()

    return (
        <div className='underwriter-banner'>
            <span className={'status-badge'}>{t('descriptors.guaranteedLoan')}</span>
        </div>
    );
}

export default UnderwriterBanner;