import React from "react";
import * as d3 from "d3";

const Arc = ({ data, index, createArc, colors }) => (
    <g key={index} className="arc">
        <path className="arc" d={createArc(data)} fill={colors(index)} />
    </g>
);

const PieGraph = (props) => {
    const createArc = d3
        .arc()
        .innerRadius(props.innerRadius)
        .outerRadius(props.outerRadius);

    const createPie = d3.pie().sort(function(d) { return d.value; });
    const colors = d3.scaleOrdinal(props.colors);
    const data = createPie(props.data);

    return (
        <div className='pie-chart' data-chart-type={props.chartType} style={{'position': props.position, 'top': props.top, 'display': props.position === 'absolute' ? 'none' : 'block'}}>
            <svg width={props.width} height={props.height}>
                <g transform={`translate(${props.xPosition}, ${props.yPosition})`}>
                    {data.map((d, i) => (
                        <Arc
                            key={i}
                            index={i}
                            data={d}
                            createArc={createArc}
                            colors={colors}
                        />
                    ))}
                </g>
            </svg>
        </div>
    );
};

export default PieGraph;
