import React, {Suspense, StrictMode} from "react";
import ReactDOM from "react-dom";
import './i18n';
import './styles/app.scss';

import Listing from "./components/Listing";

/**
 * Main app for listing platform text on FrontOffice push blocks
 *
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    let offset = 0;
    let limit = 12; // Default is max results in the loans block

    let subscriptionOriginSweden = 0;
    let subscriptionOriginNorway = 0;
    let subscriptionOriginDenmark = 0;

    let block = document.getElementById('loans-block');

    if (block) {
        limit = block.dataset.itemsFetchLimit;

        subscriptionOriginSweden = block.dataset.subscriptionOriginSweden;
        subscriptionOriginNorway = block.dataset.subscriptionOriginNorway;
        subscriptionOriginDenmark = block.dataset.subscriptionOriginDenmark;
    }

    return (
        <StrictMode>
            <Listing offset={offset} limit={limit} subscriptionOriginSweden={subscriptionOriginSweden} subscriptionOriginNorway={subscriptionOriginNorway} subscriptionOriginDenmark={subscriptionOriginDenmark} />
        </StrictMode>
    );
}

ReactDOM.render(
    <Suspense fallback={'...loading'}>
        <App />
    </Suspense>,
    document.getElementById('platform-text-listing')
);
