import axios, {Axios} from "axios";
import TokenHelper from "../../utils/TokenHelper";

/**
 * Simple module for making ezjscore calls. Set the baseURL if you are not at the root of the domain.
 * Sending requests just gives you back the return from a standard fetch
 */
export default class KameoAPIV1Request
{
    static get axiosInstance()
    {
        const headers = {
            "Accept": "application/json, text/javascript, */*; q=0.01",
            "Content-Type": "application/json",
        }

        if (this.capyToken) {
            headers["X-Capybara-Identifier"] = this.capyToken;
        }

        return axios.create({
            baseURL: KameoAPIV1Request.baseURL,
            headers,
        })
    }
    /** @type {string|boolean} */
    static get capyToken()
    {
        return TokenHelper.getCapyToken()
    }
    /** @type {string} */
    static get baseURL()
    {
        const hostname = window.location.hostname;
        if (hostname.indexOf("stage.kameo") > -1) {
            return "https://api-stage.kameo.se/v1/";
        } else if(hostname.indexOf("kameo.local") > -1 || hostname.indexOf("192") > -1) {
            return "http://api.kameo.local/v1/";
        } else {
            return "https://api.kameo.se/v1/";
        }
    }
}
