import React from "react";
import RiskRating from "./RiskRating";

const AvailableRiskRatings = [
    'A',
    'B',
    'C',
    'D',
    'E',
];

const RiskRatingBar = ({ riskRating }) => {
    return (
        <div className="risk-rating-bar">
            <div className="progress">
                {AvailableRiskRatings.map((displayRating) => (
                    <RiskRating key={displayRating} rating={displayRating} actualRating={riskRating} />
                ))}
            </div>
        </div>
    )
}

export default RiskRatingBar;