class Currency {
    #currency;
    #locale;
    #exchangeRate;
    #amount;

    constructor(currency, locale, exchangeRate, amount) {
        this.#currency = currency;
        this.#locale = locale;
        this.#exchangeRate = exchangeRate;
        this.#amount = amount;
    }

    get currency() {
        return this.#currency
    }

    get locale() {
        return this.#locale
    }

    get exchangeRate() {
        return this.#exchangeRate
    }

    get amount() {
        return this.#amount
    }
}

export default Currency;