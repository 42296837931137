import Util from "../utils/UtilHelper";
import currency from "../utils/Currency";

class UtilHelper {
    static getCountryByLocale(locale) {
        if (locale === 'swe-SE') {
            return 'se';
        }

        if (locale === 'nor-NO') {
            return 'no';
        }

        if (locale === 'dan-DK') {
            return 'dk';
        }

        throw new Error('Locale not supported');
    }

    static formatMoney(money, locale, country_code, decimals = 2) {
        let currency = Util.getCurrencyCodeByCountryCode(country_code);

        return new Intl.NumberFormat(locale, {style: 'currency', currency: currency, maximumFractionDigits: decimals}).format(money);
    }

    static parseMoneyWithoutCurrency(money, locale = 'sv-SE', country_code, decimals = 2) {
        let formattedNumber = this.formatMoney(money, locale, country_code);

        return formattedNumber.replaceAll(/[a-zA-Z]/g, '').trim();
    }

    static parseMoneyFromCurrency(money, currency, decimals = 2) {
        const locale = {
            'SEK': 'sv-SE',
            'NOK': 'no-NO',
            'DKK': 'da-DK'
        }[currency];

        const formattedNumber = Intl.NumberFormat(locale, {style: 'currency', currency: currency, maximumFractionDigits: decimals}).format(money);

        return `${formattedNumber.replaceAll(/[a-zA-Z]/g, '').trim()} ${currency}`;
    }

    static parseMoneyWithCurrency(money, locale = 'sv-SE', country_code, decimals = 2) {
        let currency = Util.getCurrencyCodeByCountryCode(country_code);
        let formattedNumber = this.formatMoney(money, locale, country_code, decimals);

        formattedNumber = formattedNumber.replaceAll(/[a-zA-Z]/g, '').trim();
        return formattedNumber + ' ' + currency;
    }
}

export default UtilHelper;