import UtilHelper from "./UtilHelper";
import UserManager from "./UserManager";
import AjaxHelper from "./AjaxHelper";
import Currency from "./Currency";
import CurrencyManager from "./CurrencyManager";

class TrapetsHelper {
    static CUSTOMER = {
        PRIVATE: 'private',
        COMPANY: 'company'
    };

    static ENTRY_KNOWLEDGE_TEST = {
        EKT_ANSWER: 'ekt_answer',
        EKT_SKIP: 'ekt_skip',
        KEY_EKT_OPTION: 'ekt_choice',
        EKT_INCOMPLETE: 0,
        EKT_FAILED: 1,
        EKT_PASSED: 2,
    };

    static QUESTIONNAIRE = {
        PRIVATE_PERSON_INITIAL_ONBOARDING: {
            NAME: 'pp-initial-onboarding',
            TOTAL_QUESTIONS: 14
        },
        CORPORATE_INITIAL_ONBOARDING: {
            NAME: 'Corporate-investors-initial-onboaring',
        },
        PRIVATE_PERSON_KNOWLEDGE_TEST: {
            NAME: 'Knowledgetest-2-0',
            TOTAL_QUESTIONS: 7
        },
        PRIVATE_SOPHISTICATED_KYC: {
            NAME: 'Suitability-classification-private-2-0',
        },
        CORPORATE_SOPHSITICATED_KYC: {
            NAME: 'Suitability-classification-legal-2-0',
        }
    };

    static OFFICIAL_LANGUAGES = {
        DA: 'da',
        NO: 'no',
        SE: 'se'
    };

    static DOCUMENT_ISSUING_COUNTRY = {
        DK: 'dk',
        NO: 'no',
        SE: 'se'
    };

    static DOCUMENTS = {
        IDENTITY_DOCUMENT: 'id_doc',
        MAILED_DOCUMENT: 'mailed_doc'
    }

    static SIGNUP = {
        BANK_ID: 'BankID',
        BANK_ID_NUMBER: 1,
        DOCUMENT_UPLOAD: 'DocumentUpload',
        DOCUMENT_UPLOAD_NUMBER: 2
    }

    // Trapets has 50 chars limit
    static CORPORATE = {
        ROLE_SIG_POWER_ALONE: 'Signatory_Power_Alone',
        ROLE_SIG_POWER_2_JOINTLY: 'Signatory_Power_Two_More_Jointly',
        ROLE_INTERNAL_REPRESENTATIVE_POA: 'Internal_Representative_POA',
        ROLE_EXTERNAL_ADVISOR_POA: 'External_Advisor_POA'
    };

    static NODE_TYPES = {
        DIV: 'div',
        DROP_DOWN_LIST: 'DropDownList',
        TEXTBOX : 'TextBox',
        INT_POSITIVE: 'IntPositive',
        TABLE: 'table',
        THEAD: 'thead'
    };

    static PANELS = {
        LIGHT: {
            NAME: 'Light registration page'
        },
        WELCOME: {
            NUMBER: 1,
            NAME: 'welcome'
        },
        WELCOME_DOCUMENT_UPLOAD: {
            NUMBER: 11,
            NAME: 'document-upload'
        },
        ADDRESS: {
            NUMBER: 2,
            NAME: 'address'
        },
        KYC_POA: {
            NUMBER: 3,
            NAME: 'kycpoa',
        },
        AML_KYC: {
            NUMBER: 4,
            NAME: 'kycaml',
        },
        KYC: {
            NUMBER: 4,
            NAME: 'kyc',
        },
        FINANCIAL_SITUATION: {
            NUMBER: 4,
            NAME: 'financial-situation',
        },
        SOPHISTICATED_KYC_CONTAINER: {
            NUMBER: 5,
            NAME: 'kyc',
        },
        SOPHISTICATED_KYC: {
            NUMBER: 6,
            NAME: 'kyc-sophisticated',
        },
        KNOWLEDGE_TEST_KYC: {
            NUMBER: 7,
            NAME: 'kyc-knowledge-test',
        },
        TERMS_AND_CONDITIONS: {
            NUMBER: 8,
            NAME: 'kameo-terms-and-conditions'
        },
        UBO: {
            NUMBER: 9,
            NAME: 'ubo'
        },
        FINISH: {
            NUMBER: 10,
            NAME: 'registration-end'
        }
    };

    static ALTUBO = {
        ALTUBOROLETYPE1: 'BOARD_CHAIRMAN',
        ALTUBOROLETYPE2: 'MANAGING_DIRECTOR',
        ALTUBOROLETYPE3: 'FINANCIAL_MANAGER',
        ALTUBOROLETYPE4: 'OTHER'
    };

    static MAINUBO = {
        PEP_F1_POLITICAL_PERSON: '1',
        PEP_F1_FAMILY_MEMBER_IS_POLITICAL_PERSON: '2',
        PEP_F1_CO_WORKER_IS_POLITICAL_PERSON: '3',
        PEP_F1_OTHER: '4',
        PEP_YES: '1',
        PEP_NO: '0',
    }

    static WELCOME = {
        get ssn() {
            return document.getElementById('defSSN').value.trim();
        },
        get orgNumber() {
          return document.getElementById('orgNumber').value.trim();
        },
        get defaultOrgNumber() {
          return document.getElementById('defOrgNumber').value.trim();
        },
        get nationality() {
            return document.getElementById('defNationality').value.trim();
        },
        get fileUpload() {
            return document.getElementById('fileUpload').files;
        },
        get token() {
            return document.querySelector('[name="ezxform_token"]').value.trim();
        },
        get error() {
            return document.getElementById('error').value.trim() === '1';
        }
    }

    static getInvestorRegistrationKey() {
        let investorRegistrationKey = document.getElementById('investorRegistrationKey').value;
        let pathname = location.pathname;
        if (pathname !== '') {
            const REGEX_FOR_INVESTOR__REGISTRATION_KEY = /([a-z0-9]{10,})/;

            let match = REGEX_FOR_INVESTOR__REGISTRATION_KEY.exec(pathname);
            if (match) {
                if (REGEX_FOR_INVESTOR__REGISTRATION_KEY.exec(match[0])) {
                    investorRegistrationKey = match[0];
                }
            }
        }

        return investorRegistrationKey;
    }

    /**
     * Returns corresponding questionnaire for a given ISO-Alpha2 country code, called from AgreementDocument.js
     * @param country
     * @returns {string}
     */
    static getQuestionnaireByCountry(country) {
        country = country.toLowerCase();

        if (country === this.DOCUMENT_ISSUING_COUNTRY.DK) {
            return 'danish-terms-and-conditions';
        }

        if (country === this.DOCUMENT_ISSUING_COUNTRY.NO) {
            return 'norwegian-terms-and-conditions';
        }

        if (country === this.DOCUMENT_ISSUING_COUNTRY.SE) {
            return 'swedish-terms-and-conditions';
        }

        throw new Error(`No questionnaire found for country ${country}`);
    }

    static getNodeById(id, item) {
        let nodeType = null;

        for (const element of Object.values(item)) {
            if (element.NodeType !== 'div') {
                if (element.Id === id) {
                    nodeType = element.NodeType;
                }
            }

            if (element.NodeType === 'div') {
                element.SubNodes.map(subNode => {
                    if (subNode.Id === id) {
                        nodeType = subNode.NodeType;
                    }
                })
            }
        }

        return nodeType;
    }

    static handleCondition(node, item, answer, setAnswer) {
        // Skip those questions with UBO fields which have empty "div"s with no Ids, Condition or SubNodes
        if (!node.Condition) {
            return;
        }

        // This returns currently executed node type e.g. DropDownList, CheckBox, RadioButtons etc.
        let nodeType = this.getNodeById(node.Condition.LHS, item);
        let lhs = node.Condition.LHS;
        let rhs = node.Condition.RHS;

        // Questions with Table structure have LHS & RHS nested deep inside Statements
        if (nodeType === null) {
            lhs = node.Condition ?. Statements ?. [0] ?. Statements ?. [0] ?. LHS;

            if (lhs) {
                rhs = node.Condition ?. Statements ?. [0] ?. Statements ?. [0] ?. RHS;
                nodeType = this.getNodeById(lhs, item);

                if (!nodeType) {
                    return console.warn("Kameo - Node type not found for node", node);
                }
            }
        }

        // Returns value for current Node type and compares with the RHS side of the condition
        let nodeValue = TrapetsHelper.getKycNodeValue(nodeType, lhs, rhs);
        if (nodeValue === rhs) {
            document.getElementById(node.Id).classList.remove('hidden');

            node.SubNodes.map(subNode => {
                // Recursively check, if a div has an Id, it's a normal conditional div, otherwise a div with a Row/Col.
                if (subNode.NodeType === 'div') {
                    if (subNode.Id) {
                        return this.handleCondition(subNode, item, answer, setAnswer);
                    }
                }
            })
        } else {

            document.getElementById(node.Id).classList.add('hidden');

            node.SubNodes.map(subNode => {
                if (subNode.NodeType === 'div') {
                    if (subNode.Id) {
                        //return this.handleCondition(subNode, item, answer, setAnswer);
                    }
                }

                if (answer[subNode.Id]) {
                    let updatedAnswer = Object.keys(answer).filter(id => {
                        if (id !== subNode.Id) {
                            return true;
                        }
                    });
                    //("Updated answer", updatedAnswer);
                    setAnswer(updatedAnswer);
                } else {
                    //("Id " + subNode.Id + " is not found in answer " + answer);
                }
            })
        }
    }

    /**
     * Disables/Enables KYC question container to stop investor from interacting with question while KYC question is being processed.
     * @param disable
     * @param parentContainer
     */
    static toggleKycQuestionContainer(disable = false, parentContainer = null) {
        let questionContainer = document.getElementById("kyc-question");
        if (parentContainer) {
            let parent = document.getElementById(parentContainer);
            if (parent) {
                questionContainer = parent.querySelector('#kyc-question');
            }
        }
        if (!questionContainer) {
            console.warn('Kameo - KYC question container not found');
            return;
        }

        if (disable) {
            questionContainer.classList.add('disable-click');
            return;
        }

        questionContainer.classList.remove('disable-click');
    }

    /**
     * Disables Navigation buttons and content area during ajax calls, Also displays loader. Disables them when ajax call is finished.
     * @param container
     * @param disable
     */
    static disableContainer (container, disable = true) {
        const previousBtn = document.getElementById("previous");
        const nextBtn = document.getElementById("next");

        if (disable) {
            previousBtn && previousBtn.setAttribute('disabled', 'disabled');
            nextBtn && nextBtn.setAttribute('disabled', 'disabled'); // Last question doesn't contain Next Button, checking for its presence, otherwise will throw error.

            UtilHelper.showLoader(true, container);
            TrapetsHelper.toggleKycQuestionContainer(true);

            return;
        }

        previousBtn && previousBtn.removeAttribute('disabled');
        nextBtn && nextBtn.removeAttribute('disabled');

        UtilHelper.showLoader(false, container);
        TrapetsHelper.toggleKycQuestionContainer(false);
    };

    static getKycNodeValue(nodeType, lhs, rhs, op) {
        switch (nodeType) {
            case 'IntPositive':
            case 'TextBox':
                return document.getElementById(lhs).value;
            case 'DropDownList':
                return document.getElementById(lhs).value;
            case 'Textarea':
                return document.getElementById(lhs).value;
            case 'CheckBox':
                let checkbox = document.getElementById(lhs);
                if (checkbox) {
                    return checkbox.checked ? 'True' : 'False'
                }

                return 'False';
            case 'CheckBoxMulti':
                let checkboxValue;
                let checkBoxes = document.getElementsByName(lhs);
                for (const checkBox of checkBoxes) {
                    if (checkBox.id === rhs) {
                        if (checkBox.checked) {
                            checkboxValue = checkBox.id;
                            break;
                        }
                    }

                    if (op === 'OP_ContainsAnyOf') {
                        let answers = rhs.split(',');
                        if (checkBox.checked) {
                            if (answers.includes(checkBox.id)) {
                                checkboxValue = checkBox.id;
                                break;
                            }
                        }
                    }
                }

                return checkboxValue;
            case 'RadioButtons':
                let radioBtns =  document.querySelectorAll(`input[name='${lhs}']`);
                let value;

                for (const radioBtn of radioBtns) {
                    if (radioBtn.checked) {
                        value = document.getElementById(radioBtn.id).value
                        break;
                    }
                }

                return value;

            case 'RadioButtonsYesNo':
                let radioBtnsYesNo =  document.querySelectorAll(`input[name='${lhs}']`);
                let answer;

                for (const radioBtn of radioBtnsYesNo) {
                    if (radioBtn.checked) {
                        answer = document.getElementById(radioBtn.id).value
                        break;
                    }
                }

                return answer;
        }
    }

    /**
     * Returns question number for the corresponding questionId in Private KYC
     * @param question
     * @returns {number}
     */
    static getPrivateKycQuestionNumberById(question) {
        let questionId = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId
        if (!questionId) {
            return;
        }

        let questions = {
            length: 14,
            1: 'Which_Citizenship',
            2: 'Skatteplikt (Scandi)',
            3: '30005',
            4: '30006',
            5: '30007',
            6: '30008',
            7: '30009',
            8: '30010',
            9: '30011',
            10: '30012',
            11: '30013',
            12: '30014',
            13: '30015'
        };

        return Array.prototype.indexOf.call(questions, questionId);
    }

    static getPrivateKnowledgeTestQuestionNumberById(question) {
        let questionId = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId

        if (!questionId) {
            // Knowledge test has some temporary heading at the top on first question which is at index 0, that's why we check for QuestionId at index 1.
            questionId = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [1] ?. QuestionId

            if (!questionId) {
                return;
            }
        }

        let questions = {
            length: 8, // one more than the actual questions because of the call below.
            1: 'EKT.A1',
            2: 'EKT.A2.1',
            3: 'EKT.A2.2',
            4: 'EKT.A3',
            5: 'EKT.A5',
            6: 'EKT.A6',
            7: 'EKT.A6.1',
        };

        return Array.prototype.indexOf.call(questions, questionId);
    }

    /**
     * Returns question number for the corresponding questionId in Corporate KYC
     * @param question
     * @returns {number}
     */
    static getCorporateKycQuestionNumberById(question) {
        let questionId = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId;
        let id = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. Id;

        // -1 returned because indexOf below returns -1 if questionId is not found. We can check for -1 on the receiving side.
        if (!questionId && !id) {
            return -1;
        }

        let questions = {
            length: 16,
            1: 'CITIZEN_COUNTRY_COMPANY',
            2: 'TAX_COUNTRY_COMPANY',
            3: '40002',
            4: '40003',
            5: '40004',
            6: '40005',
            7: '40006',
            8: '40007',
            9: '40008',
            10: '40009',
            11: '40010',
            12: '40011',
            13: '40012',
            14: '40013',
        };

        return Array.prototype.indexOf.call(questions, questionId);
    }

    static getAdditionalQuestionNumberById() {
        let questionId = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId;
        let id = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. Id;

        // -1 returned because indexOf below returns -1 if questionId is not found. We can check for -1 on the receiving side.
        if (!questionId && !id) {
            return -1;
        }

        let questions = {
            length: 5, // one more than the actual questions because of the call below.
            1: 'EKT.B1.1',
            2: 'EKT.B1.2',
            3: 'EKT.B1.3',
            4: 'EKT.B1.6',
            5: 'EKT.B1.8',
        };

        return Array.prototype.indexOf.call(questions, questionId);
    }

    static isAdditionalQuestion(questionId) {
        let additionalQuestions = [
            'EKT.B1.1',
            'EKT.B1.2',
            'EKT.B1.3',
            'EKT.B1.6',
            'EKT.B1.8',
        ];

        return additionalQuestions.includes(questionId);
    }

    static getQuestionId(question) {
        return question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId;
    }

    // Used in Sophisticated KYC, where Next button is disabled unless user selects an option which will force them to upload a file.
    // This is to prevent a user from answering additional questions while skipping the main questions.
    static handleNavigationBtn = (customer, question) => {
        let questionIdAtIndexZero = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [0] ?. QuestionId
        let questionIdAtIndexOne = question ?. data ?. content ?. message ?. RootNode ?. SubNodes ?. [1] ?. QuestionId

        // For private sophisticated kyc, if question is the one checked below then make sure at least 2 checkboxes are selected.
        if (questionIdAtIndexOne === 'Classification 4 ECSPR Fysiska personer A') {
            // it's enough if one checkbox is selected
            this.handleCheckBoxes(2);
        }

        if (questionIdAtIndexZero === 'Knowledgetest confirmation') {
            // All 2 checkboxes should be selected to confirm
            this.handleCheckBoxes(2);
        }

        // corporate sophisticated kyc
        if (questionIdAtIndexOne === 'Classification 1 MiFID II') {
            // it's enough if one checkbox is selected
            this.handleCheckBoxes(1);
        }

        // Force user to select at least on option in the additional questions
        let isAdditionalQuestion = this.isAdditionalQuestion(questionIdAtIndexZero);

        if (isAdditionalQuestion) {
            this.handleRadioButtons(1, 'radio');
        }
    }

    static handleRadioButtons(limit = 1) {
        let radioBtns =  document.querySelectorAll(`.additional-question input[type=radio]:checked`);
        let nextBtn = document.querySelector('.additional-question button#next');
        if (!nextBtn) {
            return;
        }

        // if selected checkboxes are less than the checkboxes that should be selected, disable the next button
        if (radioBtns.length < limit) {
            nextBtn.setAttribute('disabled', 'disabled');
            return;
        }

        nextBtn.removeAttribute('disabled');
    }

    static handleCheckBoxes(checkBoxCount = 1, target = 'checkbox') {
        // Only check main questions checkboxes, not child checkboxes in child questions.
        let checkBoxes = document.querySelectorAll(`.sophisticated-kyc-form [data-dom-elment-type='parent'] input[type=checkbox]:checked`);
        if (!checkBoxes) {
            return;
        }

        let nextBtn = document.querySelector('.sophisticated-kyc-form button#next');
        if (!nextBtn) {
            return;
        }

        // if selected checkboxes are less than the checkboxes that should be selected, disable the next button
        if (checkBoxes.length < checkBoxCount) {
            nextBtn.setAttribute('disabled', 'disabled');
            return;
        }

        nextBtn.removeAttribute('disabled');
    }

    static signupWithoutBankID(pesonalNumber, nationality, file, orgNumber, customerType, authenticationMethod = 'BankID') {

        let result = {
            status: 'error',
            message: 'exception.general'
        };

        if (nationality === TrapetsHelper.DOCUMENT_ISSUING_COUNTRY.DK) {
            UtilHelper.showAlert('danger', 'No file selected', 'document-upload');
            UtilHelper.showLoader(false, 'document-upload');
            result.message = 'exception.useBankID';

            return result;
        }

        if (orgNumber === null && !UtilHelper.isFileSelected("fileUpload")) {
            UtilHelper.showAlert('danger', 'No file selected', 'document-upload');
            UtilHelper.showLoader(false, 'document-upload');

            result.message = 'exception.fileNotFound';
            return result;
        }

        let userManager = new UserManager();

        return userManager.createInvestor(pesonalNumber, nationality, orgNumber, customerType, authenticationMethod).then(response => {
            if (response.data.error_text !== "") {
                UtilHelper.showLoader(false, 'document-upload');
                UtilHelper.showAlert('danger',  response.data.error_text, 'document-upload');

                result.message = response.data.error_text;
                return result
            }

            let content = response.data.content;

            if (content.status === 'error') {
                UtilHelper.showLoader(false, 'document-upload');
                UtilHelper.showAlert('danger',  content.message, 'document-upload');

                result.message = content.message;
                return result
            }

            UtilHelper.showAlert('info',  'Investor created', 'document-upload');
            UtilHelper.wait(1000);

            if(orgNumber === null) {
                //UtilHelper.showAlert('info', ('register.welcome.documentUploadInProgress'), 'document-upload');

                return this.uploadIdentityDocument(file, 'identityDocument').then(response => {
                    UtilHelper.showLoader(false, 'document-upload');

                    if (response.data.error_text !== "") {
                        UtilHelper.showLoader(false, 'document-upload');
                        UtilHelper.showAlert('danger', response.data.error_text, 'document-upload');

                        result.message = response.data.error_text;
                        return result
                    }

                    content = response.data.content;
                    if (content.status === 'error') {
                        UtilHelper.showAlert('danger', content.message, 'document-upload');

                        result.message = content.message
                        return result;
                    }

                    UtilHelper.showAlert('success', content.message, 'document-upload');

                    result.message = content.message;
                    result.userId = content.userId;
                    result.fileId = content.fileId;
                    result.originalFileName = content.originalFileName;
                    result.investorRegistrationKey = content.investorRegistrationKey;
                    result.investorRegistrationId = content.investorRegistrationId;
                    result.status = 'success';
                    result.error_text = '';

                    return result;
                });
            } else {

                result.message = content.message;
                result.userId = content.investorId;
                result.investorRegistrationKey = content.investorRegistrationKey;
                result.investorRegistrationId = content.investorId;
                result.status = 'success';
                result.error_text = '';

                return result;
            }
        });
    }

    static uploadIdentityDocument(file, fileName) {
        const formData = new FormData();

        formData.append('fileName', fileName);
        formData.append(fileName, file[0]);

        return AjaxHelper.Investor.uploadDocumentToEz(formData);
    }

    /**
     * returns false even if one agreement is not accepted
     * @returns {boolean}
     */
    static isAgreementsAccepted() {
        let agreements = document.querySelectorAll('input[type="checkbox"].agreement');
        if (!agreements) {
            return false;
        }

        for (let i = 0; i < agreements.length; i++) {
            if (!agreements[i].checked) {
                return false;
            }
        }

        return true;
    }

    /**
     * Returns total number of accounts created for a given country account.
     * @param externalBankAccounts
     * @returns {number}
     */
    static getBankAccountsTotal = (externalBankAccounts) => {
        if (!externalBankAccounts) {
            return 0;
        }

        if (!externalBankAccounts.hasOwnProperty('accounts')) {
            return 0;
        }

        return externalBankAccounts.accounts.length;
    };

    static convertCurrencyInTrapetsQuestion = (questionText, questionId, customer) => {
        // Question Är ni ett stort företag som på bolagsnivå uppfyller två av följande storlekskrav? Total balansomsutning: 200 000 000 SEK Nettoomsättning: 40 000 000 EUR Kapitalbas: 2 000 000 EUR
        let questionIds = [
            'Q2xhc3NpZmljYXRpb24gMiBNaUZJRCBJSQ==',
            'Q2xhc3NpZmljYXRpb24gNCAoRVNDUFIgSnVyaWRpc2thIHBlcnNvbmVyKQ==',
            'Q2xhc3NpZmljYXRpb24gNCAoRVNDUFIgSnVyaWRpc2thIHBlcnNvbmVyKQ==', // Eget kapital på minst 100 000 EUR, private KYC
            'Q2xhc3NpZmljYXRpb24gNCBFU0NQUiBKdXJpZGlza2EgcGVyc29uZXI=', // Eget kapital på minst 100 000 EUR, corporate KYC
            'Q2xhc3NpZmljYXRpb24gMiAoTWlGSUQgSUkp',  // Nettoomsättning på minst 2 000 000 EUR
            'Q2xhc3NpZmljYXRpb24gNCAoRVNDUFIgSnVyaWRpc2thIHBlcnNvbmVyKQ==', // Balansomslutning på minst 1 000 000 EUR,
            'Q2xhc3NpZmljYXRpb24gNCBFQ1NQUiBGeXNpc2thIHBlcnNvbmVyIEE=', // Du har en personlig bruttoinkomst på minst 60 000 EUR per beskattningsår ELLER en portfölj med finansiella instrument - definierad som likvida medel på konto och finansiella tillgångar - som överstiger 100 000 EUR
            'Q2xhc3NpZmljYXRpb24gNCBFQ1NQUiBGeXNpc2thIHBlcnNvbmVyIEI=', // Du har arbetat minst ett år inom finanssektorn i en befattning som kräver kunskap om crowdfunding, crowdlending eller andra tidiga investeringar i företag ELLER har du haft en verkställande befattning i ett företag som uppfyller minst ett av följande kriterier: Eget kapital på minst 100 000 EUR, Nettoomsättning på minst 2 000 000 EUR, Balansomslutning på minst 1 000 000 EUR
            'Q2xhc3NpZmljYXRpb24gNCBFQ1NQUiBGeXNpc2thIHBlcnNvbmVyIEIx', // Private SI: Eget kapital på minst 100 000 EUR
            'Q2xhc3NpZmljYXRpb24gMiBNaUZJRCBJSSBB', // Corporate KYC: Total balansomslutning: 20 000 000 EUR
        ];

        if (!questionIds.includes(questionId)) {
            return questionText;
        }

        if (!customer.exchangeRate) {
            return questionText;
        }

        let baseCurrency = 'SEK';
        if (customer.locale === 'dan-DK') {
            baseCurrency = 'DKK';
        }

        if (customer.locale === 'nor-NO') {
            baseCurrency = 'NOK';
        }

        if (questionText.includes('100 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 100000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('100 000 EUR', convertedAmount);
        }

        if (questionText.includes('60 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 60000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('60 000 EUR', convertedAmount);
        }

        if (questionText.includes('1 000 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 1000000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('1 000 000 EUR', convertedAmount);
        }

        if (questionText.includes('20 000 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 20000000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('20 000 000 EUR', convertedAmount);
        }

        if (questionText.includes('40 000 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 40000000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('40 000 000 EUR', convertedAmount);
        }


        if (questionText.includes('2 000 000 EUR')) {
            let currency = new Currency(baseCurrency, customer.locale, customer.exchangeRate, 2000000);
            let currencyManager = new CurrencyManager(currency);

            let convertedAmount = currencyManager.convertEuroToCurency();
            questionText = questionText.replace('2 000 000 EUR', convertedAmount);
        }

        return questionText;
    };

    /**
     * Returns true if EKT is not in progress
     * @param customer
     * @returns {boolean}
     */
    static isKnowledgeTestCompleted = (customer) => {
        return [this.ENTRY_KNOWLEDGE_TEST.EKT_FAILED, this.ENTRY_KNOWLEDGE_TEST.EKT_PASSED].includes(parseInt(customer.knowledgeTestStatus));
    };
}

export default TrapetsHelper;