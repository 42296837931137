import {useEffect, useMemo, useState} from "react";



export const useTimer = (endsAtString) => {
    const endsAt = useMemo(() => new Date(endsAtString).getTime(), [endsAtString]);
    const [timeRemaining, setTimeRemaining] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    const [intervalId, setIntervalId] = useState(null);

    const calculateTimeRemaining = () => {
        const now = new Date().getTime();
        const endsAt = new Date(endsAtString).getTime();
        const distance = endsAt - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);



        if (distance >= 0) {
            setTimeRemaining({days, hours, minutes, seconds});
            const recalculateIn = distance > 60_000 ? 10_000 : 1_000;
            setIntervalId(
                setInterval(() => {
                    calculateTimeRemaining();
                }, recalculateIn)
            );
            return;
        }

        setTimeRemaining({days: 0, hours: 0, minutes: 0, seconds: 0});
    }

    useEffect(() => {
        calculateTimeRemaining()

        return () => {
            interval && clearInterval(intervalId);
        }
    }, [endsAt]);

    return timeRemaining;
}