import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useTimer} from "../utils/subscriptionUtils";

function ScheduledLoanTime({subscription}) {
    const [t, i18n] = useTranslation();


    // If the loan is not scheduled, don't display anything
    if (subscription.subscription_state.state_id !== 10) {
        return <></>
    }

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today = today.toLocaleDateString('sv-SE', 'UTC');

    const startDate = useMemo(() => new Date(subscription.subscription_starts_at), [subscription.subscription_starts_at]);
    const subscriptionStartDate = startDate.toLocaleDateString('sv-SE');

    const hoursAndMinutes = useMemo(() => {
        return {
            hours: startDate.getHours().toString().padStart(2, '0'),
            minutes: startDate.getMinutes().toString().padStart(2, '0')
        }
    }, [startDate]);

    // if subscription start date is the same as today, then display subscription time only , else display full date
    if (today === subscriptionStartDate) {
        return (
            <div className='text-center' style={{'marginTop': '10px', 'fontWeight': 'bold'}}>
                {t('statuses.opens')} kl. {hoursAndMinutes.hours}:{hoursAndMinutes.minutes}
            </div>
        );
    }

    return (
        <div className='text-center' style={{'marginTop': '10px', 'fontWeight': 'bold'}}>
            {t('statuses.opens')} {subscriptionStartDate} kl. {hoursAndMinutes.hours}:{hoursAndMinutes.minutes}
        </div>
    )
}

export default ScheduledLoanTime;
