export default class {
    static SCHEDULED = 10;
    static BOOK_BUILDING_OPEN = 11;
    static BOOK_BUILDING_CLOSED = 12;
    static ALLOCATING =  15;
    static ALLOCATION_FAILED = 16;
    static OPEN = 20;
    static CLOSED = 30;
    static MATCHED =  40;
    static SETTLED = 50;
    static FAILED =  60;

    static closedBookBuildingStates() {
        return [
            this.BOOK_BUILDING_CLOSED,
            this.ALLOCATING,
            this.ALLOCATION_FAILED
        ];
    }

    static closedStates() {
        return [
            this.CLOSED,
            this.MATCHED,
            this.SETTLED,
            this.FAILED,
        ];
    }

    static openStates() {
        return [
            this.BOOK_BUILDING_OPEN,
            this.OPEN,
        ]
    }
}