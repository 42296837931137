import TrapetsHelper from "./TrapetsHelper";

/**
 * This class does not in fact help you with your utilities 🏗📞👷
 */
class UtilHelper {

    static REGEX = {
        NUMBERS_ONLY: /^[0-9\b]+$/
    };

    /**
     * Serializes form data
     * @param form
     * @returns {FormData}
     */
    static serialize(form) {
        return new FormData(form);
    }

    /**
     * Shows alert message at the top of the Panel
     * @param type
     * @param message
     * @param container
     * @param persist
     * @param delay
     */
    static showAlert = (type, message, container = null, persist = false, delay = 500) => {
        // hide previous alert if any
        this.hideAlert(container);

        let alert = document.querySelector(".alert");
        if (container) {
            alert = document.querySelector(`#${container} .alert`);
        }
        if (!alert) {
            return;
        }

        this.wait(delay).then(response => {
            alert = this.#removeCssClass(alert, ['alert-danger', 'alert-success', 'alert-warning', 'alert-info', 'closed', 'hide']);
            let contents = alert.querySelector("div.contents");
            contents.innerHTML = message;
            alert.classList.add('alert-' + type);

            // if an alert has a persist class, Parent component will not hide the alert, only the element who has shown the alert, will have the responsibility to hide it.
            if (persist) {
                alert.classList.add('alert-persist');
            }

            alert.classList.remove('closed');
        });
    };

    /**
     * Hides alert
     * @param container
     * @param removePersist
     */
    static hideAlert = (container = null, removePersist = true) => {
        let alert = document.querySelector(".alert");
        if (container) {
            alert = document.querySelector(`#${container} .alert`);
        }
        if (!alert) {
            return;
        }

        // only the element which has shown the alert, can hide the alert.
        if (!removePersist && alert.classList.contains('alert-persist')) {
            return;
        }

        let contents = alert.querySelector(`div.contents`);
        this.wait(250).then(response => {
            contents.innerHTML = '';
            //alert = this.#removeCssClass(alert, ['alert-danger', 'alert-success', 'alert-warning']);
            alert.classList.remove('alert-danger', 'alert-success', 'alert-warning');
            if (alert) {
                alert.classList.add('closed');
            }
        });
    };

    /**
     * Animates to the top smoothly
     * @param xAxis
     * @param yAxis
     */
    static scrollToTop = (xAxis = 0, yAxis = 0) => {
        window.scrollTo({
            left: xAxis,
            top: yAxis,
            behavior: 'smooth'
        });
    };

    static scrollToHeader = (headerId) => {
        let header = document.getElementById(headerId);

        if (!header) {
            console.log("Header not found, cannot scroll to header");
            return;
        }

        header.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    };

    /**
     * @todo fix: display adddress form only if required data is missing from the form
     * @param content
     * @returns {boolean}
     */
    static displayAddressForm = content => {
        return true;
        if (content === null) {
            ("inside content");
            //return true;
        }

        if (typeof content === undefined) {
            ("inside undefined");
            return true;
        }

        if (content.hasOwnProperty('firstName') === false) {
            ("inside first name " + content.firstName);
            (JSON.stringify(content, null, 4));
            return true;
        }

        ("It comes to this point");

        return false;
    };

    /**
     * Converts string to uper case
     * @param strToConvert
     * @returns {*}
     */
    static toUpperCase(strToConvert) {
        if (strToConvert === null || typeof strToConvert === 'undefined' || strToConvert === '') {
            return '';
        }
        return strToConvert.toUpperCase();
    }

    /**
     * Converts Post number to 722 22 format
     * @param postNumber
     * @returns {*}
     */
    static formatPostNumber(postNumber) {
        if (postNumber === null || typeof postNumber === 'undefined' || postNumber === '') {
            return '';
        }

        let postValue = postNumber.split('');
        if (postValue.length < 5) {
            return postNumber;
        }

        // if space already exists return Post number.
        if (/\s/g.test(postValue)) {
            // If space is entered at wrong position
            if (postNumber.indexOf(' ') !== 3) {
                return this.#addSpaceToPostNumber(postNumber);
            }

            return postValue.join('').toString();
        }

        // Enter a space at index 3
        return this.#addSpaceToPostNumber(postNumber);
    }

    /**
     * @param postNumber
     * @returns {*}
     */
    static #addSpaceToPostNumber(postNumber) {
        postNumber = postNumber.replaceAll(' ', '');
        let postValue = postNumber.split('');
        postValue.splice(3, 0, ' ');

        return postValue.join('').toString();
    }

    /**
     * Shows a checkmark to the right of any input field, currently used for PersonnalNo & Email activation statuses.
     * @param show
     * @param container
     */
    static manageCheckMark(show, container) {
        let checkMark = document.querySelector(".check-mark");

        let parent = document.querySelector(`#${container}`);
        if (!parent) {
            return;
        }

        checkMark = parent.querySelector(`.check-mark`);
        if (!checkMark) {
            return;
        }

        if (show) {
            checkMark.style.display = 'block';
            return;
        }

        checkMark.style.display = 'none';
    }

    /**
     * Capitalize first letter of a string
     * @param text
     * @returns {string|*}
     */
    static capitalizeFirstLetter(text) {
        // return if empty or null text
        if (!text) {
            return text;
        }

        //text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    /**
     * Capitalize each word of the text
     * @param text
     * @returns {*|string}
     */
    static capitalizeString(text) {
        if (!text) {
            return text;
        }

        // trim any spaces
        text = text.trim();

        let words = text.split(" ");
        if (words.length === 0) {
            return text;
        }

        let capitalized = "";
        words.forEach((word, index) => {
            if (index !== 0) {
                capitalized = capitalized + " ";
            }

            capitalized = capitalized + this.capitalizeFirstLetter(word);
        });

        return capitalized;
    }

    static #removeCssClass(container, cssClasses) {
        cssClasses.map((cssClass) => {
            if (container && container.classList.contains(cssClass)) {
                container.classList.remove(cssClass);
            }
        });

        return container;
    }

    /**
     * Creates a delay of given milliseconds.
     * @param milliseconds
     * @returns {Promise<void>}
     */
    static async wait(milliseconds) {
        await this.#delay(milliseconds);
    }

    /**
     * Private method, used in wait.
     * @param milliseconds
     * @returns {Promise}
     */
    static #delay = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

    static isFileSelected(id) {
        let element = document.getElementById(id);
        return element.files.length !== 0;
    }

    static showLoader(enabled, panel) {
        let loader = document.querySelector(`div#${panel} img.loader`);

        if (!loader) {
            loader = document.querySelector(`#${panel} img.loader`);
        }

        if (loader) {
            if (enabled) {
                loader.classList.remove('hidden');
                return;
            }

            loader.classList.add('hidden');
        }
    }

    /**
     * @todo move these constants to TrapetsHelper and get values from there, see example below for pp-initial-onboarding
     * @param customerType
     * @param kycType
     * @returns {string}
     */
    static getQuestionnaireType(customerType, kycType) {
        if (customerType === 'company') {
            if (kycType === 'kycaml') {
                return TrapetsHelper.QUESTIONNAIRE.CORPORATE_INITIAL_ONBOARDING.NAME;
            }

            if (kycType === 'kyc-sophisticated') {
                return TrapetsHelper.QUESTIONNAIRE.CORPORATE_SOPHSITICATED_KYC.NAME;
            }

            if (kycType === 'kyc-knowledge-test') {
                return TrapetsHelper.QUESTIONNAIRE.PRIVATE_PERSON_KNOWLEDGE_TEST.NAME;
            }

            if (kycType === 'kycpoa') {
                return 'corporate-onboarding-poa';
            }

            throw new Error('Invalid corporate investor KYC type');
        }

        if (kycType === 'kycaml') {
            return TrapetsHelper.QUESTIONNAIRE.PRIVATE_PERSON_INITIAL_ONBOARDING.NAME;
        }

        if (kycType === 'kyc-sophisticated') {
            return TrapetsHelper.QUESTIONNAIRE.PRIVATE_SOPHISTICATED_KYC.NAME;
        }

        if (kycType === 'kyc-knowledge-test') {
            return TrapetsHelper.QUESTIONNAIRE.PRIVATE_PERSON_KNOWLEDGE_TEST.NAME;
        }


        throw new Error('Invalid private investor KYC type ' + kycType);
    }

    /**
     * Get country from the URL TLD. It returns IsoAlpha3 codes, used in countries list in KYC questionnaire.
     * @returns {string}
     */
    static getCountry() {
        let domain = window.location.origin.split('.').pop();

        // On localhost/development environment return SE.
        if (domain === 'local') {
            if (/\/dk\/investor*/.test(window.location.pathname)) {
                return 'DK';
            }
            if (/\/no\/investor*/.test(window.location.pathname)) {
                return 'NO';
            }

            return 'SE';
        }

        if (domain === 'dk') {
            return 'DK';
        }

        if (domain === 'no') {
            return 'NO';
        }

        // For stage url http://stage.kameo.se/dk/investor/...
        if (domain === 'se') {
            if (/\/dk\/investor*/.test(window.location.pathname)) {
                return 'DK';
            }
            if (/\/no\/investor*/.test(window.location.pathname)) {
                return 'NO';
            }

            return 'SE';
        }

        throw new Error("Invalid TLD");
    }

    static getSiteIsoAlpha3CountryCode() {
        let country = this.getCountry();
        country = country.toLowerCase();

        if (country === TrapetsHelper.DOCUMENT_ISSUING_COUNTRY.DK) {
            return 'dnk';
        }

        if (country === TrapetsHelper.DOCUMENT_ISSUING_COUNTRY.NO) {
            return 'nor';
        }

        if (country === TrapetsHelper.DOCUMENT_ISSUING_COUNTRY.SE) {
            return 'swe';
        }

        throw new Error('Country code not supported' + country);
    }

    /**
     * Returns translated country name.
     * @param isoAlpha2
     * @returns {string}
     */
    static getCountryByIsoAlpha2Code(isoAlpha2) {
        if (isoAlpha2.toLowerCase() === 'dk') {
            return 'general.country.denmark';
        }
        if (isoAlpha2.toLowerCase() === 'no') {
            return 'general.country.norway';
        }
        if (isoAlpha2.toLowerCase() === 'se') {
            return 'general.country.sweden';
        }

        throw new Error('Invalid country code');
    }

    static getLanguageByCountryCode(isoAlpha2) {
        if (isoAlpha2.toLowerCase() === 'dk') {
            return 'register.kameoTC.currency.danishKronor';
        }

        if (isoAlpha2.toLowerCase() === 'no') {
            return 'register.kameoTC.currency.norwegianKronor';
        }

        if (isoAlpha2.toLowerCase() === 'se') {
            return 'register.kameoTC.currency.swedishKronor';
        }

        throw new Error('Invalid country code');
    }

    static getCurrencyNameByCountryCode(isoAlpha2) {
        if (isoAlpha2.toLowerCase() === 'dk') {
            return 'register.kameoTC.currency.danishKronor';
        }

        if (isoAlpha2.toLowerCase() === 'no') {
            return 'register.kameoTC.currency.norwegianKronor';
        }

        if (isoAlpha2.toLowerCase() === 'se') {
            return 'register.kameoTC.currency.swedishKronor';
        }

        throw new Error('Invalid country code');
    }

    /**
     * @param {string} countryCode
     */
    static getCurrencyCodeByCountryCode(countryCode) {
        const map = {
            se: 'SEK',
            sv: 'SEK',
            sek: 'SEK',
            swe: 'SEK',
            'sv-se': 'SEK',
            'swe-se': 'SEK',
            sweden: 'SEK',

            no: 'NOK',
            nb: 'NOK',
            nok: 'NOK',
            nor: 'NOK',
            'nb-no': 'NOK',
            'nor-no': 'NOK',
            norway: 'NOK',

            dk: 'DKK',
            da: 'DKK',
            dan: 'DKK',
            dkk: 'DKK',
            'da-dk': 'DKK',
            'dan-dk': 'DKK',
            denmark: 'DKK',
        };

        const currencyCode = map[countryCode.toLowerCase()];
        if (currencyCode) {
            return currencyCode;
        }

        throw new Error('Invalid country code');
    }

    // Takes ISO-639-1 language code e.g. sv and returns the corresponding locale in sv-Swe format
    static getLocaleByLanguage(language) {
        if (language === 'sv') {
            return 'swe-SE';
        }

        if (language === 'da') {
            return 'dan-DK';
        }

        if (language === 'no') {
            return 'nor-NO';
        }

        if (language === 'en') {
            return 'en-Gbr';
        }

        throw new Error('Language not supported');
    }

    /**
     * Sort countries list in DNK -> NOR -> SWE -> Rest of the world
     * @param countries
     * @param country
     * @param id
     * @returns []
     */
    static sortCountriesList(countries, country, id) {
        // If it's not a countries list, return the same value.
        countries.map(country => {
            if (country.Id !== 'AFG') {
                return countries;
            }
        });

        let selectedCountries = ['SWE', 'NOR', 'DNK'];

        // Added for corporate question "I hvilket land er virksomheden registreret?" where there are multiple countries list which should differ from the main country
        // Therefore, the main registration country is removed from the top. Requested by Pia.
        let selectedNodeIds = [
            "Q0lUSVpFTl9DT1VOVFJZX1NFQ09ORF9DT01QQU5ZwqTCpMKkM8KkMMKkwqTCpA==",
            "Q0lUSVpFTl9DT1VOVFJZX1NFQ09ORF9DT01QQU5ZwqTCpMKkM8KkMcKkwqTCpA==",
            "Q0lUSVpFTl9DT1VOVFJZX1NFQ09ORF9DT01QQU5ZwqTCpMKkM8KkMsKkwqTCpA=="
        ];

        // if above question id, then don't show current country at the top as previously it has been selected and answered, now show other countries.
        if (selectedNodeIds.includes(id)) {
            let isoAlpha3CountryCode = this.getSiteIsoAlpha3CountryCode();
            selectedCountries = selectedCountries.filter((value, index) => value !== isoAlpha3CountryCode.toUpperCase());

            if (isoAlpha3CountryCode) {
                selectedCountries.unshift(isoAlpha3CountryCode.toUpperCase());
            }
        }

        // if user country is provided, move that country to the top of the list.
        if (country) {
            country = country.toUpperCase();

            //selectedCountries = selectedCountries.filter(item => item !== country);
            //selectedCountries.push(country);
        }

        return countries.sort((a, b) => {
            return selectedCountries.indexOf(b.Id) - selectedCountries.indexOf(a.Id);
        });
    }

    static getCurrentPage() {
        let url = new URL(window.location.href);
        let pathName = url.pathname;

        if (pathName.indexOf(TrapetsHelper.PANELS.WELCOME.NAME) !== -1) {
            return TrapetsHelper.PANELS.WELCOME.NAME;
        }
        if (pathName.indexOf('start') !== -1) {
            return 'documentUpload';
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.ADDRESS.NAME) !== -1) {
            return TrapetsHelper.PANELS.ADDRESS.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.KYC_POA.NAME) !== -1) {
            return TrapetsHelper.PANELS.KYC_POA.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.AML_KYC.NAME) !== -1) {
            return TrapetsHelper.PANELS.AML_KYC.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.FINANCIAL_SITUATION.NAME) !== -1) {
            return TrapetsHelper.PANELS.FINANCIAL_SITUATION.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.KNOWLEDGE_TEST_KYC.NAME) !== -1) {
            return TrapetsHelper.PANELS.KNOWLEDGE_TEST_KYC.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.SOPHISTICATED_KYC.NAME) !== -1) {
            return TrapetsHelper.PANELS.SOPHISTICATED_KYC.NAME;
        }
        if (pathName.indexOf('terms') !== -1) {
            return TrapetsHelper.PANELS.TERMS_AND_CONDITIONS.NAME;
        }
        if (pathName.indexOf(TrapetsHelper.PANELS.UBO.NAME) !== -1) {
            return TrapetsHelper.PANELS.UBO.NAME;
        }
        if (pathName.indexOf('finished') !== -1) {
            return 'finished';
        }
        if (pathName.match(/investor\/update\/{0,1}/)) {
            return TrapetsHelper.PANELS.WELCOME.NAME;
        }
    }

    static openCurrentPage(currentPage = "") {
        if (currentPage === "") {
            currentPage = this.getCurrentPage();
        }

        // Wait for 500 milliseconds because in case of corporate customer, state is usually not set and the header click is called, in that case the component is not laoded and thus page doesn't open as header is not found.
        setTimeout(() => {
            let pageHeader = document.querySelector(`[aria-controls=${currentPage}]`);
            if (!pageHeader) {
                return;
            }

            pageHeader.click();
        }, 500);

    }

    static navigateToPage(url, reactNavigator) {
        reactNavigator(url);
        reactNavigator(0);
    }

    static updateUrlPath(path) {
        let url = new URL(window.location.href);

        url.pathname = path;
        window.location.href = url;
    }

    static getRandomNumber(maxNumber = 100) {
        return Math.floor(Math.random() * maxNumber);
    }

    static getCountryPrefix(country = null) {
        let countryCode = country;
        if (window.location.hostname.indexOf('stage') === -1) {
            return '';
        }

        if (!country) {
            if (window.location.pathname.indexOf('/no') !== -1) {
                countryCode = 'NO';
            }

            if (window.location.pathname.indexOf('/dk') !== -1) {
                countryCode = 'DK';
            }
        }

        if (countryCode === 'SE') {
            return '';
        }

        if (countryCode === 'DK') {
            return '/dk';
        }

        if (countryCode === 'NO') {
            return '/no';
        }

        return '';
    }

    static getInvestorProtectionPageLink(language) {
        let readMore = '/Investerarskydd';
        if (language === 'no') {
            readMore = '/Investorbeskyttelse';
        }

        if (language === 'da') {
            readMore = '/Investorbeskyttelse';
        }

        return readMore;
    }

    static getLocaleByCurrency(currency) {
        currency = currency.toLowerCase();

        if (currency === 'sek') {
            return 'sv-SE';
        }
        if (currency === 'dnk') {
            return 'dan-DK';
        }
        if (currency === 'nok') {
            return 'nor-NO';
        }

        throw new Error('Could not find locale by currency' + currency);
    }

    static getCurrentDate() {
        return new Date().toJSON().slice(0, 10);
    }

    /**
     * It formats the number based on the given currency.
     * @param value
     * @param currency
     * @param formatToCurrency
     * @param displayDecimals
     * @returns {string}
     */
    static numberFormat(value, currency, formatToCurrency = true, displayDecimals = true) {
        let locale = {'SEK': 'sv-SE', 'NOK': 'nb-NO', 'DKK': 'da-DK'};
        let genCurrency = {'SEK': 'kr', 'NOK': 'kr', 'DKK': 'kr.'};

        if (formatToCurrency) {
            return new Intl.NumberFormat(locale[currency], {
                style: 'currency',
                currency,
                maximumFractionDigits: displayDecimals ? 2 : 0,
            }).format(value).replace(genCurrency[currency], '').trim();
        }

        return new Intl.NumberFormat(locale[currency]).format(value);
    }

    /**
     * Copy data to clipboard
     * @param data
     */
    static copyToClipBoard = (data, callBack) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(data)
                .then(() => {
                    if (typeof callBack === 'function') {
                        callBack();
                    }
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
        } else {
            this.copyToClipboardFallback(data, callBack);
        }
    };

    /**
     * Fallback if navigator.clipboard is undefined
     * @param data
     */
    static copyToClipboardFallback = (data, callBack) => {
        const textarea = document.createElement("textarea");
        textarea.value = data;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
            if (typeof callBack === 'function') {
                callBack();
            }
        } catch (error) {
            console.error("Failed to copy text: ", error);
        }
        document.body.removeChild(textarea);
    };

    static getCurrentUnixTimestamp() {
        return Math.floor(Date.now() / 1000);
    }

    static formatISO8601ToUnixTimestamp(date) {
        return Math.floor(Date.parse(date) / 1000);
    }

}

export default UtilHelper
