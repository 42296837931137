class TokenHelper {
    static getCapyToken() {
        return document.getElementById('api-data').dataset?.capyToken;
    }

    static getBearerToken() {
        return document.getElementById('api-data').dataset?.accessToken;
    }
}

export default TokenHelper;