class CurrencyManager {
    #currency;
    #investmentUnit= 500

    constructor(currency) {
        this.#currency = currency;
    }

    convertEuroToCurency() {
        let convertedAmount = this.#currency.exchangeRate * this.#currency.amount;

        return this.format(convertedAmount, this.#currency.currency, this.#currency.locale);
    }

    format(amount, currency, locale) {
        let convertedAmount = Math.round(amount / this.#investmentUnit) * this.#investmentUnit
        return Intl.NumberFormat(locale, {style: 'currency', currency: currency, maximumFractionDigits: 0}).format(convertedAmount);
    }
}

export default CurrencyManager;
